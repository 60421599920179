import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { KiteSelect } from '@kite/react-kite';
import { SearchBar, Button, TitleTable } from '../../components';
import './Search.scss';
import { searchAssets } from '../../apiCalls/AssetService';
import { AssetInterface } from '../../Interfaces';

const Search = withRouter((props) => {
  const [type, setType] = useState('All');
  const [titles, setTitles] = useState<AssetInterface[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const onSearch = async (searchTerm) => {
    setLoading(true);
    const limit = 20;
    const offset = 0;
    const titles = await searchAssets(searchTerm, 'title', type, limit, offset);
    setTitles(titles as AssetInterface[] | null);
    setLoading(false);
  };

  return (
    <div className="search-page">
      <h1 className="margin-25px" data-testid="searchPage-Title">
        Search Titles
      </h1>
      <div className="search-controls" data-testid="searchPage-searchFields">
        <KiteSelect
          className="no-margin"
          disabled={false}
          errorMessage=""
          id="TitleType"
          inputProps={{}}
          label="Title Type"
          margin=""
          maxWidth=""
          name="TitleTple"
          onChange={({ target: { value } }) => setType(value)}
          placeholder=""
          tooltip=""
          value={type}
        >
          <option value="All">All</option>
          <option value="Movie">Movie</option>
          <option value="Series">Series</option>
          <option value="Episode">Episode</option>
        </KiteSelect>
        <div className="search-bar-wrapper" data-testid="searchPage-searchInput">
          <SearchBar
            onSearch={onSearch}
            placeholder="Search by Title or TMSID"
            onChange={setSearchValue}
          />
        </div>
        <div data-testid="searchTitles-button">
          <Button text="SearchTitles" onClick={() => onSearch(searchValue)} />
        </div>
      </div>
      <TitleTable
        titles={titles || []}
        onRowClick={({ id }) => props.history.push(`/titles/${id}`)}
        onSortClick={() => {}}
        sortHeader=""
        ascending={true}
        loading={loading}
        noDataMessage="Search for Titles by Title or TMSID"
      />
    </div>
  );
});

export default Search;
