import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import './Reports.scss';
import { ExistingReportsTable } from '../../components';
import { KitePagination, KiteButton } from '@kite/react-kite';

import { getReports } from '../../apiCalls/ReportService';
import { PaginationInterface } from '../../Interfaces';

const Reports = withRouter((props) => {
  const [loading, setLoading] = useState<boolean | null>(null);
  const [pagination, setPagination] = useState<PaginationInterface>({
    sortBy: 'created_at',
    ascending: false,
    page: 1,
    pageSize: 50,
  });
  const [total, setTotal] = useState(0);
  const [reports, setReports] = useState<string[]>([]);

  useEffect(() => {
    loadReports(pagination);
  }, [pagination]);

  const loadReports = async (pagination) => {
    setLoading(true);
    const { total, reports } = await getReports(pagination).catch(
      (getReportsError) => {
        return { total: 0, reports: [] };
      }
    );
    setReports(reports);
    setTotal(total);
    setLoading(false);
  };

  const handleSortClick = (sortHeader) => {
    const { sortBy, ascending } = pagination;

    if (sortHeader === sortBy) {
      setPagination({ ...pagination, ascending: !ascending });
    } else {
      setPagination({ ...pagination, sortBy: sortHeader, ascending: true });
    }
  };

  const handleNextPage = () => {
    const { page } = pagination;
    const newPage = page + 1;
    setPagination({ ...pagination, page: newPage });
  };

  const handlePreviousPage = () => {
    const { page } = pagination;
    const newPage = page - 1;
    setPagination({ ...pagination, page: newPage });
  };

  const handlePageSelect = (page) => setPagination({ ...pagination, page });

  const handleRowClick = ({ id }) => props.history.push(`/reports/${id}`);

  const { ascending, sortBy, page, pageSize } = pagination;
  const totalPages = Math.ceil(total / pageSize);
  return (
    <div>
      <main className="reports-containter" data-testid="reportsSwim_pageB">
        <div className="flex justify-between align-center width-100 reports-header">
          <h2 className="all-movies__title" data-testid="reportsPage-Title">
            Missing Trailer Reports
          </h2>
          <div className="flex align-center" data-testid="reportsCreate-button">
            <KiteButton
              className="search-bar__button"
              type={'primary'}
              onClick={() => props.history.push('/reports/new')}
            >
              Create New Report
            </KiteButton>
          </div>
        </div>
        {loading !== null ? (
          <ExistingReportsTable
            report={reports}
            onRowClick={handleRowClick}
            onSortClick={handleSortClick}
            ascending={ascending}
            sortHeader={sortBy}
            loading={loading}
            noDataMessage="Sorry, no results found."
          />
        ) : (
          <h3 className="margin-50px">
            Select swimlanes and click generate report.
          </h3>
        )}
        {totalPages > 1 && (
          <KitePagination
            totalPages={totalPages}
            currentPage={Number(page) || 1}
            onNextPage={handleNextPage}
            onPrevPage={handlePreviousPage}
            onPageSelect={handlePageSelect}
          />
        )}
      </main>
    </div>
  );
});

export default Reports;
