import React, { useContext } from 'react';
import Context from '../../context';
import { Button } from '../../components';
import './Modal.scss';
import { ButtonProps } from '../../Interfaces';

const Modal = () => {
  const { modal, setModal } = useContext(Context);
  if (!modal.open) return null;
  return (
    <div className="modal-backdrop" onClick={(e) => setModal({ open: false })}>
      <div
        className="app-modal"
        onClick={(e) => e.stopPropagation()}
        style={modal.style}
        data-testid="trailerPage-sidePreview"
      >
        {modal.renderContent ? (
          modal.renderContent()
        ) : (
          <>
            <div>
              {modal.header ? (
                <h2 className="center-text">{modal.header}</h2>
              ) : null}
              {modal.text ? (
                <div className="center-text">{modal.text}</div>
              ) : null}
            </div>
            <div className="modal-buttons">
              {modal.secondaryButton ? (
                <Button {...(modal.secondaryButton as ButtonProps)} />
              ) : null}
              {modal.primaryButton ? (
                <Button {...(modal.primaryButton as ButtonProps)} />
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
