import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { KiteLoader, KiteIcon } from '@kite/react-kite';
import './Title.scss';
import { searchAssets } from '../../apiCalls/AssetService';
import {
  setAsPrimary,
  removeTrailerFromTitle,
  archiveTrailer,
} from '../../apiCalls/TrailerService';
import { StatusSelect, VideoPlayer } from '../../components';
import Context from '../../context';
import { createVideoObject } from '../../utils/helpers';
import { AssetInterface } from '../../Interfaces';

const Title = withRouter(
  ({
    match: {
      params: { tms_id },
    },
  }) => {
    const [asset, setAsset] = useState<AssetInterface | null>(null);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('MATCHED');
    const { setModal } = useContext(Context);

    useEffect(() => {
      loadAsset();
    }, []);

    const loadAsset = async () => {
      setLoading(true);
      const [asset] = await searchAssets(tms_id, 'tms_id');
      setAsset(asset);
      setLoading(false);
    };
    if (loading)
      return (
        <div className="loader-container">
          <KiteLoader />
        </div>
      );
    if (!asset)
      return (
        <div className="loader-container">
          <h1 data-testid="asset-missingResult">
            No Result Found for TMSID: {tms_id}
          </h1>
        </div>
      );

    return (
      <div className="title-page">
        <div className="title-banner" data-testid="asset-pageHeader">
          {asset && asset.title}
        </div>
        <div className="asset-page-main">
          <div className="asset-metadata" data-testid="asset-pageData">
            <div className="tile-page-asset-text">
              <h2>Metadata</h2>
              <div className="flex">
                <div className="text-title" data-testid="asset-DataId">
                  TMSID
                </div>
                {asset.id}
              </div>
              <div className="flex">
                <div className="text-title" data-testid="asset-DataTitle">
                  Title
                </div>
                {asset.title}
              </div>
              <div className="flex">
                <div className="text-title" data-testid="asset-DataYear">
                  Year
                </div>
                {asset.releaseYear}
              </div>
              <div className="flex">
                <div className="text-title" data-testid="asset-DataRating">
                  Rating
                </div>
              </div>
              <div className="flex">
                <div className="text-title" data-testid="asset-DataTime">
                  Min
                </div>
                {asset.duration / 60}
              </div>
              <div className="flex">
                <div className="text-title" data-testid="asset-DataGenre">
                  Genres
                </div>
                {asset.genres}
              </div>
              <div>
                <div className="text-title" data-testid="asset-DataDescription">
                  Description
                </div>
              </div>
              <div>{asset.description}</div>
            </div>
          </div>
          <div className="asset-page-trailers" data-testid="asset-pageImages">
            <h2>Images</h2>
            <div className="flex justify-between">
              <div className="image-type">
                Poster
                <img
                  src={`https://cdnimg.spectrum.net/imageserver/program/${tms_id}?twccategory=Poster&height=500`}
                  className="title-images"
                />
              </div>
              <div className="image-type">
                Hero
                <img
                  src={`https://cdnimg.spectrum.net/imageserver/program/${tms_id}?twccategory=Hero&height=500`}
                  className="title-images"
                />
              </div>
              <div className="image-type">
                Iconic
                <img
                  src={`https://cdnimg.spectrum.net/imageserver/program/${tms_id}?twccategory=Iconic&height=500`}
                  className="title-images"
                />
              </div>
            </div>
          </div>
          <div className="asset-page-trailers" data-testid="asset-pageTrailers">
            <div className="flex justify-between align-end">
              <h2>Trailers</h2>
              <StatusSelect status={status} setStatus={setStatus} />
            </div>
            {asset.trailers
              .filter(
                (t) =>
                  !status ||
                  (t.status === status && !t.archived_at) ||
                  (status === 'ARCHIVED' && t.archived_at)
              )
              .map((trailer) => {
                const primaryEligible =
                  !trailer.is_primary &&
                  trailer.status === 'MATCHED' &&
                  !trailer.archived_at;
                return (
                  <div key={trailer.id} className={`assets-page-trailer`}>
                    <h3>
                      {trailer.title}
                      {trailer.is_primary ? (
                        <span className="primary-label">Primary Trailer</span>
                      ) : null}
                    </h3>
                    <VideoPlayer
                      video={
                        createVideoObject(
                          trailer
                        ) as videojs.VideoJsPlayerOptions
                      }
                    />
                    <div
                      className="trailer-actions"
                      data-testid="assetPage-trailerActions"
                    >
                      <div
                        className={`trailer-action-button ${
                          primaryEligible ? '' : 'hidden'
                        }`}
                      >
                        <KiteIcon
                          name="asterisk"
                          size="17px"
                          color="#005499"
                          onClick={() => setModal({ open: false })}
                        />
                        <div
                          className={
                            primaryEligible
                              ? 'set-as-primary'
                              : 'primaryIneligible'
                          }
                          onClick={async () => {
                            if (primaryEligible) {
                              setLoading(true);
                              await setAsPrimary(trailer.id, tms_id);
                              loadAsset();
                            }
                          }}
                        >
                          Set as Primary
                        </div>
                      </div>
                      <div
                        className="trailer-action-button"
                        onClick={async () => {
                          setModal({
                            open: true,
                            header: 'De-Associate Trailer',
                            text: `Are you sure you want to de-associate trailer from this title?`,
                            style: {
                              width: 400,
                              height: 250,
                            },
                            primaryButton: {
                              text: 'De-Associate',
                              className: 'negative',
                              onClick: async () => {
                                setModal({ open: false });
                                setLoading(true);
                                await removeTrailerFromTitle(
                                  trailer.id,
                                  trailer.status
                                );
                                await loadAsset();
                              },
                            },
                            secondaryButton: {
                              text: 'Cancel',
                              className: 'outline',
                              onClick: () => setModal({ open: false }),
                            },
                          });
                        }}
                      >
                        <KiteIcon
                          name="record-cancel"
                          size="17px"
                          color="#005499"
                        />
                        <div className="set-as-primary">
                          De-Associate Trailer
                        </div>
                      </div>
                      <div
                        className={`trailer-action-button ${
                          trailer.archived_at ? 'hidden' : ''
                        }`}
                        onClick={async () => {
                          setModal({
                            open: true,
                            header: 'Archive Trailer',
                            text: `Are you sure you want to archive this trailer?`,
                            style: {
                              width: 400,
                              height: 250,
                            },
                            primaryButton: {
                              text: 'Archive Trailer',
                              className: 'negative',
                              onClick: async () => {
                                setModal({ open: false });
                                setLoading(true);
                                await archiveTrailer(trailer.id);
                                await loadAsset();
                              },
                            },
                            secondaryButton: {
                              text: 'Cancel',
                              className: 'outline',
                              onClick: () => setModal({ open: false }),
                            },
                          });
                        }}
                      >
                        <KiteIcon
                          name="start-over"
                          size="17px"
                          color="#d6312b"
                        />
                        <div className="remove-trailer">Archive Trailer</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
);

export default Title;
