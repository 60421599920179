import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import Context from '../../context';
import './ImageReports.scss';
import {
  ImageReportsTable,
  Chart,
  CardTriplet,
  Button,
  ManualTriggerModal,
} from '../../components';
import { getImageReports } from '../../apiCalls/ImageReportService';
import { useQuery } from '../../utils/helpers';
import { KiteLoader, KitePagination } from '@kite/react-kite';
import { PaginationInterface, ReportInterface } from '../../Interfaces';

const DEFAULT_QUERY = {
  sortBy: 'created_at',
  sortDirection: 'DESC',
  ascending: false,
  page: 1,
  pageSize: 10,
};

interface ImageReportInterface {
  count: number;
  missing_image_count: number;
  created_at: string;
  id: string;
  percent_missing: number;
}

const Images = withRouter((props) => {
  const {
    history,
    location: { pathname },
  } = props;
  const { setModal, modal } = useContext(Context);
  const { query, createQueryString } = useQuery(DEFAULT_QUERY);
  const [loading, setLoading] = useState<boolean | null>(null);
  const [imageReports, setImageReports] = useState<ImageReportInterface[]>([]);

  const [pagination, setPagination] = useState<PaginationInterface>(
    DEFAULT_QUERY
  );
  const [visibleRows, setVisibleRows] = useState<ImageReportInterface[]>([]);
  const [total_image_reports, set_total_image_reports] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  useEffect(() => {
    loadReports();
  }, [query.sortDirection, query.page, query.pageSize]);
  useEffect(() => {
    setTotalPages(Math.ceil(total_image_reports / pagination.pageSize));
  }, [total_image_reports]);

  const loadReports = async () => {
    setLoading(true);
    const queryString = createQueryString(query);
    console.log({ queryString });
    const { total, image_reports } = await getImageReports(queryString).catch(
      (getReportsError) => {
        return { total: 0, image_reports: [] };
      }
    );
    set_total_image_reports(total);
    setImageReports(image_reports);
    setLoading(false);
  };

  const reloadReports = async () => {
    loadReports();
  };

  const updateQuery = (query) => {
    const queryString = createQueryString(query);
    history.push(`${pathname}${queryString}`);
  };

  const handleSortClick = (sortHeader) => {
    const { sortBy, sortDirection } = query;
    updateQuery({
      ...DEFAULT_QUERY,
      ...query,
      page: 1,
      sortDirection:
        sortBy !== sortHeader || sortDirection === 'DESC' ? 'ASC' : 'DESC',
      sortBy: sortHeader,
    });
  };

  function getNextRun() {
    const refDate = new Date();
    refDate.setHours(0, 0, 0, 0);
    refDate.setDate(refDate.getDate() + ((7 - refDate.getDay()) % 7));
    return `${
      refDate.getMonth() + 1
    }/${refDate.getDate()}/${refDate.getFullYear()}`;
  }
  const handleRowClick = ({ id }) => {
    history.push(`/image-reports/${id}/networks`);
  };

  const onRunReport = (e) => {
    e.stopPropagation();
    if (modal.open) setModal({ open: false });
    else
      setModal({
        open: true,
        renderContent: () => {
          return <ManualTriggerModal />;
        },
      });
  };
  const updateVisibleRows = (newPage) => {
    const start = (newPage - 1) * pagination.pageSize;
    const end = start + pagination.pageSize;
    setVisibleRows(imageReports.slice(start, end));
  };
  const handleNextPage = () => {
    const { page, pageSize } = pagination;
    const newPage = page + 1;
    updateQuery({
      ...DEFAULT_QUERY,
      ...query,
      page: newPage,
      pageSize: pageSize,
    });
    setPagination({ ...pagination, page: page + 1 });
    updateVisibleRows(page + 1);
  };

  const handlePreviousPage = () => {
    const { page, pageSize } = pagination;
    const newPage = page - 1;
    updateQuery({
      ...DEFAULT_QUERY,
      ...query,
      page: newPage,
      pageSize: pageSize,
    });
    setPagination({ ...pagination, page: newPage });
    updateVisibleRows(newPage);
  };

  const handlePageSelect = (newPage) => {
    const { pageSize } = pagination;
    updateQuery({
      ...DEFAULT_QUERY,
      ...query,
      page: newPage,
      pageSize: pageSize,
    });
    setPagination({ ...pagination, page: newPage });
    updateVisibleRows(newPage);
  };
  const { page } = pagination;

  return (
    <div className="image-reports-page">
      <main className="image-reports-main">
        <h2
          className="width-100 image-reports-header flex"
          data-testid="imagesPage-Title"
        >
          Missing Image Reports
          <div className="manual-trigger">
            <div data-testid="imagesPage-scheduledReport">next scheduled report: {getNextRun()}</div>{' '}
            <Button
              className="manual-button"
              onClick={onRunReport}
              text="Run Report Now"
            />
          </div>
        </h2>
        {loading ? (
          <>
            <KiteLoader
              buttonChild={false}
              className="chart-loader"
              loaderTitle="Loading"
              percent={null}
              showPercent
              size="large"
              status=""
              useLight={false}
            />
          </>
        ) : (
          <>
            <Chart imageReports={imageReports} />
            <CardTriplet
              cards={[
                {
                  data:
                    imageReports &&
                    imageReports[0]?.created_at
                      .split(' ')[0]
                      .replace(/-/g, '/'),
                  title: 'Last Run',
                },
                {
                  data: imageReports && imageReports[0]?.missing_image_count,
                  title: 'Titles Missing Images',
                },
                {
                  data: imageReports && `${imageReports[0]?.percent_missing}%`,
                  title: 'Percent Missing',
                },
              ]}
              loading={loading}
            />
            <div
              className="image-reports-table-box"
              data-testid="imagesPage-mainTable"
            >
              <ImageReportsTable
                onRowClick={handleRowClick}
                reloadReports={reloadReports}
                imageReports={imageReports || []}
                onSortClick={handleSortClick}
                sortHeader={query.sortBy}
                sortDirection={query.sortDirection}
                loading={loading}
                noDataMessage="Sorry, no results found."
              />
              {totalPages > 1 && (
                <KitePagination
                  totalPages={totalPages}
                  currentPage={Number(page) || 1}
                  onNextPage={handleNextPage}
                  onPrevPage={handlePreviousPage}
                  onPageSelect={handlePageSelect}
                />
              )}
            </div>
          </>
        )}
      </main>
    </div>
  );
});

export default Images;
