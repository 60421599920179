import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import './ImageReportFolders.scss';
import {
  CardTriplet,
  ImageReportTitlesTable,
  ImageReportFoldersTable,
} from '..';
import { KiteLoader, KitePagination } from '@kite/react-kite';
import {
  getImageReportFolder,
  getImageReportFolderSummary,
} from '../../apiCalls/ImageReportService';
import { FolderInterface } from '../../Interfaces';
import { useQuery } from '../../utils/helpers';

const DEFAULT_PAGINATION = {
  sortBy: 'title',
  sortDirection: 'ASC',
  page: 1,
  pageSize: 2,
};

const ImageReportFolders = withRouter((props) => {
  const {
    history,
    location: { pathname },
    match: {
      params: { report_id },
    },
  } = props;

  const { query, createQueryString } = useQuery(DEFAULT_PAGINATION);

  const updateQuery = (query) => {
    const queryString = createQueryString(query);
    history.push(`${pathname}${queryString}`);
  };

  const [loading, setLoading] = useState<boolean | null>(true);

  const [total, setTotal] = useState(0);
  const [folder, setFolder] = useState<FolderInterface | null>(null);

  useEffect(() => {
    loadReport();
    window.scrollTo(0, 0);
  }, [JSON.stringify(query), report_id]);

  const loadReport = async () => {
    setLoading(true);
    const queryString = createQueryString(query);
    const [folder, folderSummary] = await Promise.all([
      getImageReportFolder(report_id, queryString),
      getImageReportFolderSummary(report_id, queryString),
    ]);

    setFolder({ ...folder, ...folderSummary });
    setTotal(folder.total);
    setLoading(false);
  };

  const reloadReport = async () => {
    loadReport();
  };

  const handleSortClick = (sortHeader) => {
    const { sortBy, sortDirection } = query;
    updateQuery({
      ...query,
      page: 1,
      sortDirection:
        sortBy !== sortHeader || sortDirection === 'DESC' ? 'ASC' : 'DESC',
      sortBy: sortHeader,
    });
  };

  const handleNextPage = () => {
    updateQuery({
      ...query,
      page: (query.page || 1) + 1,
    });
  };
  const handlePreviousPage = () => {
    updateQuery({
      ...query,
      page: query.page ? query.page - 1 : 1,
    });
  };

  const handlePageSelect = (page) =>
    updateQuery({
      ...query,
      page: page,
    });

  const { sortDirection, sortBy, page, pageSize } = query;

  const totalPages = Math.ceil(total / pageSize!);

  const handleRowClick = (row) => {
    if (folder?.has_subfolders) {
      updateQuery({
        ...query,
        page: 1,
        path: encodeURIComponent(row.path),
        title: encodeURIComponent(row.title),
      });
    } else history.push(`/titles/${row.tms_id}`);
  };
  console.log({ folder });
  return (
    <div className="image-node-report-page">
      <main className="image-node-report-main">
        {loading ? (
          <>
            <KiteLoader
              buttonChild={false}
              className="chart-loader"
              loaderTitle="Loading"
              percent={null}
              showPercent
              size="large"
              status=""
              useLight={false}
            />
          </>
        ) : (
          <>
            <CardTriplet
              loading={loading}
              cards={[
                { data: folder?.title, title: 'Folder' },
                { data: folder?.count, title: 'Count' },
                { data: folder?.missing_image_count, title: 'Missing Images' },
              ]}
            />
            <div className="path-selector">
              {folder?.path
                ? decodeURIComponent(`${folder.path}/${folder.title}`)
                    .split('/')
                    .slice(1)
                    .map((level, index, array) => {
                      const is_terminus = index + 1 == array.length;
                      // const path =
                      // const title = encodeURIComponent(array[index])
                      // const url = `/image-reports/${report_id}/folders?path=${path}&title=${title}`;
                      return (
                        <div
                          key={index}
                          className={`${
                            is_terminus ? 'terminus-' : ''
                          }path-node`}
                          // onClick={() => is_terminus || history.push(url)}
                          onClick={() =>
                            is_terminus ||
                            updateQuery({
                              ...query,
                              page: 1,
                              path: encodeURIComponent(
                                `.${index > 0 ? '/' : ''}${array
                                  .slice(0, index)
                                  .join('/')}`
                              ),
                              title: encodeURIComponent(array[index]),
                            })
                          }
                        >
                          {index === 0 ? './' : ''}
                          {level}
                          {is_terminus ? '' : '/'}
                        </div>
                      );
                    })
                : null}
            </div>
            <div
              className="image-node-report-table-box"
              data-testid="imagesPage-pathsTable"
            >
              {folder?.has_subfolders ? (
                <>
                  <ImageReportFoldersTable
                    onRowClick={handleRowClick}
                    reloadReport={reloadReport}
                    folders={folder?.folders || []}
                    onSortClick={handleSortClick}
                    sortHeader={sortBy}
                    sortDirection={sortDirection}
                    loading={loading}
                    noDataMessage="Sorry, no results found."
                  />
                </>
              ) : (
                <ImageReportTitlesTable
                  onRowClick={handleRowClick}
                  reloadReport={reloadReport}
                  titles={folder?.titles || []}
                  onSortClick={handleSortClick}
                  sortHeader={sortBy}
                  sortDirection={sortDirection}
                  loading={loading}
                  noDataMessage="Sorry, no results found."
                />
              )}
            </div>
            {totalPages > 1 && (
              <KitePagination
                totalPages={totalPages}
                currentPage={Number(page) || 1}
                onNextPage={handleNextPage}
                onPrevPage={handlePreviousPage}
                onPageSelect={handlePageSelect}
              />
            )}
          </>
        )}
      </main>
    </div>
  );
});

export default ImageReportFolders;
