import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { KiteIcon, KiteLoader } from '@kite/react-kite';
import './ImageReportTitlesByNetwork.scss';
import { CardTriplet, ImageReportTitlesTable } from '..';
import { KitePagination } from '@kite/react-kite';
import {
  getImageReportTitlesByNetwork,
  getImageReportTitlesByNetworkSummary,
} from '../../apiCalls/ImageReportService';
import { ImageReportInterface } from '../../Interfaces';
import { useQuery } from '../../utils/helpers';

const DEFAULT_PAGINATION = {
  sortBy: 'has_image',
  sortDirection: 'ASC',
  page: 1,
  pageSize: 50,
};

const ImageReportTitlesByNetwork = withRouter((props) => {
  const {
    history,
    location: { pathname, search },
    match: {
      params: { report_id, network },
    },
  } = props;

  const { query, createQueryString } = useQuery(DEFAULT_PAGINATION);

  const updateQuery = (query) => {
    const queryString = createQueryString(query);
    history.push(`${pathname}${queryString}`);
  };

  const [loading, setLoading] = useState<boolean | null>(true);

  const [total, setTotal] = useState(0);
  const [imageReport, setImageReport] = useState<ImageReportInterface | null>(
    null
  );

  useEffect(() => {
    loadReport();
    window.scrollTo(0, 0);
  }, [JSON.stringify(query), network, report_id, search]);

  const loadReport = async () => {
    setLoading(true);
    const queryString = createQueryString(query);
    const [imageReport, imageReportSummary] = await Promise.all([
      getImageReportTitlesByNetwork(report_id, network, queryString),
      getImageReportTitlesByNetworkSummary(report_id, network, queryString),
    ]);
    if (search.includes('excludeImages')) {
      imageReport.titles = imageReport.titles.filter((ir) => !ir.has_image);
    }
    setImageReport({ ...imageReport, ...imageReportSummary });
    setTotal(imageReport.count);
    setLoading(false);
  };

  const reloadReport = async () => {
    loadReport();
  };

  const handleSortClick = (sortHeader) => {
    const { sortBy, sortDirection } = query;
    updateQuery({
      ...query,
      page: 1,
      sortDirection:
        sortBy !== sortHeader || sortDirection === 'DESC' ? 'ASC' : 'DESC',
      sortBy: sortHeader,
    });
  };

  const handleNextPage = () => {
    updateQuery({
      ...query,
      page: (query.page || 1) + 1,
    });
  };

  const handlePreviousPage = () => {
    updateQuery({
      ...query,
      page: query.page ? query.page - 1 : 1,
    });
  };

  const handlePageSelect = (page) =>
    updateQuery({
      ...query,
      page: page,
    });

  const { sortDirection, sortBy, page, pageSize } = query;

  const totalPages = Math.ceil(total / pageSize!);

  const handleRowClick = ({ path, title, is_folder, tms_id }) => {
    if (is_folder)
      history.push(
        `/image-reports/${report_id}/image-nodes?path=${encodeURIComponent(
          path
        )}/${encodeURIComponent(title)}`
      );
    else history.push(`/titles/${tms_id}`);
  };

  const createTabURL = (url) => {
    const { excludeAdult, excludeMusic, division_vodid } = query;
    const queryString = createQueryString({
      excludeAdult,
      excludeMusic,
      division_vodid,
    });
    return `${url}${queryString}`;
  };

  return (
    <div className="image-node-report-page">
      <main className="image-node-report-main">
        {loading ? (
          <>
            <KiteLoader
              buttonChild={false}
              className="chart-loader"
              loaderTitle="Loading"
              percent={null}
              showPercent
              size="large"
              status=""
              useLight={false}
            />
          </>
        ) : (
          <>
            <CardTriplet
              loading={loading}
              cards={[
                { data: network, title: 'Network' },
                { data: imageReport?.count, title: 'Titles' },
                {
                  data: imageReport?.missing_image_count,
                  title: 'Missing Images',
                },
              ]}
            />
            <div
              onClick={() => {
                history.push(
                  createTabURL(`/image-reports/${report_id}/networks`)
                );
              }}
              className="all-networks"
              data-testid="imagesPage-networksLink"
            >
              <KiteIcon name="chevron-left" size="20px" color="#0062b2" /> Back
              to all networks
            </div>
            <div
              className="image-node-report-table-box"
              data-testid="imagesPage-networkTable"
            >
              <ImageReportTitlesTable
                onRowClick={handleRowClick}
                reloadReport={reloadReport}
                titles={imageReport?.titles || []}
                onSortClick={handleSortClick}
                sortHeader={sortBy}
                sortDirection={sortDirection}
                loading={loading}
                noDataMessage="Sorry, no results found."
              />
            </div>
            {totalPages > 1 && (
              <KitePagination
                totalPages={totalPages}
                currentPage={Number(page) || 1}
                onNextPage={handleNextPage}
                onPrevPage={handlePreviousPage}
                onPageSelect={handlePageSelect}
              />
            )}
          </>
        )}
      </main>
    </div>
  );
});

export default ImageReportTitlesByNetwork;
