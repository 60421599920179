import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import './Trailers.scss';
import { TrailerTable, AssetsSidePanel } from '../../components';
import { KitePagination, KiteIcon } from '@kite/react-kite';
import { getTrailers } from '../../apiCalls/TrailerService';
import { VideoJsPlayerOptions } from 'video.js';
import { SearchBar, VideoPlayer, StatusSelect } from '../../components';
import { TrailerInterface, PaginationInterface } from '../../Interfaces';
import { createVideoObject } from '../../utils/helpers';

const DEFAULT_PAGINATION = {
  sortBy: 'updated_at',
  ascending: false,
  page: 1,
  pageSize: 20,
};

const Trailers = withRouter((props) => {
  const [loading, setLoading] = useState<boolean | null>(null);
  const [pagination, setPagination] = useState<PaginationInterface>(
    DEFAULT_PAGINATION
  );
  const [trailerSearch, setTrailerSearch] = useState('');
  const [total, setTotal] = useState(0);
  const [trailers, setTrailers] = useState<string[]>([]);

  const [selectedTrailer, setSelectedTrailer] = useState<
    TrailerInterface | undefined
  >(undefined);
  const [video, setVideo] = useState<VideoJsPlayerOptions | null>(null);
  const [status, _setStatus] = useState('PROCESSED_BY_MDS');
  const setStatus = (s) => {
    setPagination((p) => ({ ...p, page: 1 }));
    _setStatus(s);
  };

  useEffect(() => {
    loadTrailers(pagination, trailerSearch, status);
    window.scrollTo(0, 0);
  }, [pagination, trailerSearch, status]);

  const loadTrailers = async (pagination, trailerSearch, status) => {
    setLoading(true);
    const { total, video_assets } = await getTrailers(
      pagination,
      trailerSearch,
      status
    ).catch((getReportsError) => {
      return { total: 0, video_assets: [] };
    });
    setTrailers(video_assets);
    setTotal(total);
    setLoading(false);
  };

  const reloadTrailers = async () => {
    loadTrailers(pagination, trailerSearch, status);
  };

  const handleSortClick = (sortHeader) => {
    const { sortBy, ascending } = pagination;

    if (sortHeader === sortBy) {
      setPagination({ ...pagination, ascending: !ascending });
    } else {
      setPagination({ ...pagination, sortBy: sortHeader, ascending: true });
    }
  };

  const handleNextPage = () => {
    const { page } = pagination;
    const newPage = page + 1;
    setPagination({ ...pagination, page: newPage });
  };

  const handlePreviousPage = () => {
    const { page } = pagination;
    const newPage = page - 1;
    setPagination({ ...pagination, page: newPage });
  };

  const handlePageSelect = (page) => setPagination({ ...pagination, page });

  const { ascending, sortBy, page, pageSize } = pagination;
  const totalPages = Math.ceil(total / pageSize);

  const onRowClick = async (trailer) => {
    const videoObject = createVideoObject(trailer);
    setSelectedTrailer(trailer);
    setVideo(videoObject as VideoJsPlayerOptions);
  };

  const onTrailerSearch = (v) => {
    setSelectedTrailer(undefined);
    setPagination(DEFAULT_PAGINATION);
    setTrailerSearch(v);
  };

  return (
    <div className="trailers-page">
      <main className="video-assets-main">
        <h2
          className="width-100 video-assets-header"
          data-testid="trailersPage-Title"
        >
          Trailers
        </h2>
        {video ? (
          <div className="video-player-wrapper">
            <VideoPlayer autoplay={true} video={video} />
          </div>
        ) : (
          <div className="select-video" data-testid="trailersPage-videoPanel">
            <div className="play-circle">
              <KiteIcon name="play-f" size="40px" color="white" />
            </div>
            Select a trailer to view the video.
          </div>
        )}
        <div className="table-box">
          {total ? (
            <div className="trailer-table-count" data-testid="trailersPage-count">
              <b>Count:</b> {total}
            </div>
          ) : null}
          <div
            className="flex align-end"
            data-testid="trailersPage-searchSection"
          >
            <StatusSelect setStatus={setStatus} status={status} />
            <SearchBar onSearch={onTrailerSearch} />
          </div>
          <TrailerTable
            status={status}
            selectedTrailer={selectedTrailer}
            reloadTrailers={reloadTrailers}
            trailers={trailers}
            onRowClick={onRowClick}
            onSortClick={handleSortClick}
            ascending={ascending}
            sortHeader={sortBy}
            loading={loading}
            noDataMessage="Sorry, no results found."
          />
        </div>
        {totalPages > 1 && (
          <KitePagination
            totalPages={totalPages}
            currentPage={Number(page) || 1}
            onNextPage={handleNextPage}
            onPrevPage={handlePreviousPage}
            onPageSelect={handlePageSelect}
          />
        )}
      </main>
      <AssetsSidePanel
        selectedTrailer={selectedTrailer}
        reloadTrailers={reloadTrailers}
      />
    </div>
  );
});

export default Trailers;
