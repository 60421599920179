/* tslint:disable */
import React, { useRef, useLayoutEffect } from 'react';
import { KiteLoader } from '@kite/react-kite';
import './BarChart.scss';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

const BarChart = ({ networks, loading }) => {
  const barChart = useRef<am4charts.XYChart | null>(null);

  useLayoutEffect(() => {
    if (networks) {
      var chart = am4core.create('barchartdiv', am4charts.XYChart);
      chart.fill = am4core.color('#ffffff');
      // Add data
      networks.sort((a, b) =>
        a.missing_image_count > b.missing_image_count ? -1 : 1
      );
      chart.data = networks.slice(0, 25);
      chart!.preloader!.disabled = true;
      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'network';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.grid.template.strokeWidth = 0;
      categoryAxis.renderer.labels.template.horizontalCenter = 'right';
      categoryAxis.renderer.labels.template.verticalCenter = 'middle';
      categoryAxis.renderer.labels.template.rotation = 270;
      categoryAxis!.tooltip!.disabled = true;
      categoryAxis.renderer.minHeight = 110;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minWidth = 50;
      valueAxis.renderer.minGridDistance = 50;

      // Create series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.sequencedInterpolation = true;
      series.dataFields.valueY = 'missing_image_count';
      series.dataFields.categoryX = 'network';
      series.tooltipText = '[{categoryX}: bold]{valueY}[/]';
      series.columns.template.strokeWidth = 0;

      series.tooltip!.pointerOrientation = 'vertical';
      series.columns.template.column.fillOpacity = 0.8;

      // on hover, make corner radiuses bigger
      var hoverState = series.columns.template.column.states.create('hover');
      hoverState.properties.cornerRadiusTopLeft = 0;
      hoverState.properties.cornerRadiusTopRight = 0;
      hoverState.properties.fillOpacity = 1;

      series.columns.template.adapter.add('fill', function (fill, target) {
        let gradient = new am4core.LinearGradient();
        gradient.addColor(am4core.color('#003057'));
        gradient.addColor(am4core.color('#004157'));
        gradient.rotation = 90;
        return gradient;
      });

      // Cursor
      chart.cursor = new am4charts.XYCursor();

      barChart!.current! = chart;

      return () => {
        chart.dispose();
      };
    }
  }, [networks]);

  return (
    <div className="barchart-wrapper">
      {!networks || loading ? (
        <KiteLoader
          buttonChild={false}
          className="chart-loader"
          loaderTitle="Loading"
          percent={null}
          showPercent
          size="large"
          status=""
          useLight={false}
        />
      ) : (
        <div id="barchartdiv" data-testid="imagesPage-barChart" />
      )}
    </div>
  );
};
export default BarChart;
