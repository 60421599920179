import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './Report.scss';
import { ReportTable, SwimlaneSelect } from '../../components';
import { KitePagination, KiteButton } from '@kite/react-kite';

import Context from '../../context';
import {
  createReport,
  generateReport,
  getReport,
} from '../../apiCalls/ReportService';
import { ReportInterface, PaginationInterface } from '../../Interfaces';

const defaultPagination = {
  sortBy: 'title',
  ascending: false,
  page: 1,
  pageSize: 50,
};

const Report = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const isExistingReport = id !== 'new';
  const { swimlanes } = useContext(Context);
  const [report, setReport] = useState<ReportInterface[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [createdAt, setCreatedAt] = useState<string>('');
  const [pagination, setPagination] = useState<PaginationInterface>(
    defaultPagination
  );
  const [textInput, setTextInput] = useState<string>('');
  const [visibleRows, setVisibleRows] = useState<ReportInterface[]>([]);
  const [selectedSwimlanes, setSelectedSwimlanes] = useState<
    (string | object)[]
  >([]);
  const [loading, setLoading] = useState<boolean | null>(null);

  useEffect(() => {
    if (isExistingReport && !report.length) {
      getReport(id).then(({ report, created_at, count }) => {
        setReport(report);
        setTotal(count);
        const temp = created_at.split(' ')[0].split('-');
        const date = `${temp[1]}/${temp[2]}/${temp[0]}`;
        setCreatedAt(date);
      });
    } else {
      handlePageSelect(1);
    }
  }, [report]);

  const handleSwimlaneSelect = (selected: (string | object)[]) => {
    if (!selected.length) setReport([]);
    setLoading(null);
    setSelectedSwimlanes(selected);
  };

  const handleSwimlaneTextChange = (e: Event) =>
    setTextInput((e.target as HTMLTextAreaElement).value);

  const handleSortClick = (sortHeader) => {
    const { sortBy, ascending } = pagination;

    if (sortHeader === sortBy) {
      setPagination({ ...pagination, ascending: !ascending });
      const sorted = [...report].reverse();
      setReport(sorted);
    } else {
      setPagination({ ...pagination, sortBy: sortHeader, ascending: true });
      const sorted = [...report].sort((a, b) =>
        a[sortHeader].localeCompare(b[sortHeader])
      );
      setReport(sorted);
    }
  };

  const createCSV = (report) => {
    const csvStartingStr =
      'data:text/csv;charset=utf-8,Title,Year,TMS ID,File Name,Path,Swimlane\n';

    return report.reduce((acc, x) => {
      const { tms_id, title, swimlane, year } = x;
      const expectedTitle = tms_id + '-' + title.replace(/[\ \/]/g, '_');
      let ODA_FOLDER = 'ODA';
      if (
        window.location.host.includes('develop.oda') ||
        window.location.host.includes('localhost')
      )
        ODA_FOLDER = 'ODA-QA';
      if (window.location.host.includes('staging.oda'))
        ODA_FOLDER = 'ODA-STAGING';

      const expectedFilePath = `/Charter/${ODA_FOLDER}/Trailers/${expectedTitle}`;
      return (
        acc +
        `"${title}","${year}","${tms_id}","${expectedTitle}","${expectedFilePath}","${swimlane}"\n`
      );
    }, csvStartingStr);
  };

  const downloadCSV = (csv) => {
    const encodedUri = encodeURI(csv);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'my_data.csv');
    document.body.appendChild(link);
    link.click();
  };

  const exportReport = async () => {
    const csv = createCSV(report);
    downloadCSV(csv);

    if (!isExistingReport) {
      createReport(report);
    }
  };

  const updateVisibleRows = (newPage) => {
    const start = (newPage - 1) * pagination.pageSize;
    const end = start + pagination.pageSize;
    setVisibleRows(report.slice(start, end));
  };

  const handleNextPage = () => {
    const { page } = pagination;
    const newPage = page + 1;
    setPagination({ ...pagination, page: newPage });
    updateVisibleRows(newPage);
  };

  const handlePreviousPage = () => {
    const { page } = pagination;
    const newPage = page - 1;
    setPagination({ ...pagination, page: newPage });
    updateVisibleRows(newPage);
  };

  const handlePageSelect = (newPage) => {
    setPagination({ ...pagination, page: newPage });
    updateVisibleRows(newPage);
  };

  const initializeReport = async () => {
    setLoading(true);
    const newReport = (await generateReport(
      selectedSwimlanes
    )) as ReportInterface[];
    setReport(newReport);
    setTotal(newReport.length);
    setLoading(false);
  };

  const { ascending, sortBy, page } = pagination;
  const totalPages = Math.ceil(report.length / pagination.pageSize);
  return (
    <div>
      <main className="reports-containter" data-testid="reportsSwim-createPage">
        <Link
          to="/reports"
          className="nav__link flex align-self-start"
          data-testid="reportsSwim-return"
        >
          <h3>{'<'} Back to Missing Trailers Reports</h3>
        </Link>
        <div className="flex justify-between align-center width-100">
          <h2 className="all-movies__title" data-testid="reportsPage-Title">
            Missing Trailer Report: {createdAt}
          </h2>
          <div className="flex align-center" data-testid="reportsPage-buttonSet">
            <KiteButton
              className={`search-bar__button ${report.length ? '' : 'hidden'}`}
              type={'outline'}
              onClick={exportReport}
            >
              Export Report
            </KiteButton>
            {isExistingReport ? null : (
              <KiteButton
                className={`search-bar__button margin-25px ${
                  isExistingReport ? 'hidden' : ''
                }`}
                type={'primary'}
                onClick={initializeReport}
                disabled={!selectedSwimlanes.length}
              >
                Generate Report
              </KiteButton>
            )}
          </div>
        </div>
        <div
          className="flex justify-between width-100 align-center"
          data-testid="reportsPage-swimSelect"
        >
          <SwimlaneSelect
            initialData={swimlanes}
            onChange={handleSwimlaneTextChange}
            onSelectionsChange={handleSwimlaneSelect}
            value={textInput}
          />
          <h4 className="margin-25px align-self-end" data-testid="reportsPage-assetCount">
            {total ? `Asset Count: ${total}` : null}
          </h4>
        </div>
        <ReportTable
          report={visibleRows}
          onSortClick={handleSortClick}
          ascending={ascending}
          sortHeader={sortBy}
          loading={loading}
          noDataMessage={
            loading === null
              ? "Select swimlanes and click 'Generate Report' to get started."
              : 'Sorry, no results found.'
          }
        />
        {totalPages > 1 && (
          <KitePagination
            totalPages={totalPages}
            currentPage={Number(page) || 1}
            onNextPage={handleNextPage}
            onPrevPage={handlePreviousPage}
            onPageSelect={handlePageSelect}
          />
        )}
      </main>
    </div>
  );
};

export default Report;
