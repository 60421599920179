import React from 'react';

import { NavLink } from 'react-router-dom';

import './Nav.scss';
import { KiteIcon } from '@kite/react-kite';

const Nav = () => {
  return (
    <header className="nav">
      <h1 data-testid="headerTitle">On Demand Audit Tool</h1>
      <nav>
        <ul>
          <li data-testid="headerTab-images">
            <NavLink
              className="nav__link nav__new-trailers"
              activeClassName="nav__link--active"
              to="/image-reports"
            >
              <span
                className="nav__link-content"
                tabIndex={-1}
                data-link="New Trailers"
              >
                <KiteIcon
                  className="nav__link-icon"
                  name="asterisk"
                  color="#9ba9bd"
                />
                <span className="nav__new-trailers-text">Images</span>
              </span>
            </NavLink>
          </li>
          <li data-testid="headerTab-trailers">
            <NavLink
              className="nav__link nav__new-trailers"
              activeClassName="nav__link--active"
              to="/trailers"
            >
              <span
                className="nav__link-content"
                tabIndex={-1}
                data-link="New Trailers"
              >
                <KiteIcon
                  className="nav__link-icon"
                  name="folder"
                  color="#9ba9bd"
                />
                <span className="nav__new-trailers-text">Trailers</span>
              </span>
            </NavLink>
          </li>
          <li data-testid="headerTab-reports">
            <NavLink
              className="nav__link"
              activeClassName="nav__link--active"
              to="/reports"
            >
              <span
                className="nav__link-content"
                tabIndex={-1}
                data-link="Reports"
              >
                <KiteIcon
                  className="nav__link-icon"
                  name="document-f"
                  color="#9ba9bd"
                />
                Reports
              </span>
            </NavLink>
          </li>
          <li data-testid="headerTab-swimlanes">
            <NavLink
              className="nav__link"
              activeClassName="nav__link--active"
              to="/swimlanes"
            >
              <span
                className="nav__link-content"
                tabIndex={-1}
                data-link="Swimlanes"
              >
                <KiteIcon
                  className="nav__link-icon"
                  name="on-demand"
                  color="#9ba9bd"
                />
                Swimlanes
              </span>
            </NavLink>
          </li>
          <li data-testid="headerTab-search">
            <NavLink
              className="nav__link"
              activeClassName="nav__link--active"
              to="/search"
            >
              <span
                className="nav__link-content"
                tabIndex={-1}
                data-link="Search"
              >
                <KiteIcon
                  className="nav__link-icon"
                  name="search"
                  color="#9ba9bd"
                />
                Search
              </span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Nav;
