import React, { useState, useRef } from 'react';

import { KiteIcon } from '@kite/react-kite';

import './SwimlaneSearchBar.scss';
import { SearchBarProps } from '../../Interfaces';

const SwimlaneSearchBar = (props: SearchBarProps) => {
  const { onSearch } = props;
  const [value, _onChange] = useState('');
  const iconbutton = useRef<HTMLDivElement>(null);

  const onChange = (val) => {
    if (props.onChange) props.onChange(val);
    _onChange(val);
  };

  const handleKeydown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearch(value);
    }
  };

  const handleClear = (e) => {
    e.stopPropagation();
    onChange('');
    onSearch('');
  };

  const handleChange = (value) => {
    //  iconbutton.current.style.display="none"
    onChange(value);

    if (!value) {
      onSearch('');
    }
  };

  return (
    <div className="swimlane-search-bar" data-testid="swimlanesPage-searchInput">
      <div className="swimlane-search-bar__input-wrapper">
        <div className="swimlane-search-bar__icon align-end">
          <KiteIcon name="search" size="22px" />
        </div>
        <div>
          <input
            onChange={(e) => handleChange(e.target.value)}
            onKeyDown={(e) => handleKeydown(e)}
            onClick={(e) => {
              console.log(iconbutton.current);
            }}
            className="swimlane-search-bar__input"
            value={value}
          />
        </div>

        {value && (
          <KiteIcon
            className="swimlane-search-bar__clear"
            name="x-circle"
            onClick={handleClear}
            size="25px"
          />
        )}
      </div>
    </div>
  );
};

export default SwimlaneSearchBar;
