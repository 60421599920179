import React, { useState, useContext } from 'react';

import { KiteIcon, KiteLoader } from '@kite/react-kite';
import { searchAssets } from '../../apiCalls/AssetService';
import { addTrailerToAsset } from '../../apiCalls/TrailerService';
import Context from '../../context';

import './AssetsSidePanel.scss';
import { SearchBar, AssetModal, SearchSelect } from '../../components';
import { AssetInterface, AssetsSidePanelProps } from '../../Interfaces';

const AssetsSidePanel = ({
  selectedTrailer,
  reloadTrailers,
}: AssetsSidePanelProps) => {
  const [assets, setAssets] = useState<AssetInterface[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchBy, setSearchBy] = useState('title');
  const { setModal } = useContext(Context);

  const onSearch = async (searchTerm) => {
    if (searchTerm) {
      setLoading(true);
      const assets = await searchAssets(searchTerm, searchBy);
      setAssets(assets as AssetInterface[] | null);
      setLoading(false);
    }
  };

  const openAssetModal = (asset) => {
    setModal({
      open: true,
      renderContent: () => <AssetModal asset={asset} />,
    });
  };

  const addToTitle = (selectedTrailer, asset) => {
    setModal({
      open: true,
      header: 'Add Trailer To Title',
      text: `Are you sure you want to add '${selectedTrailer.title}' to '${asset.title}'?`,
      style: {
        width: 400,
        height: 250,
      },
      primaryButton: {
        text: 'Add Trailer',
        onClick: async () => {
          await addTrailerToAsset(selectedTrailer.id, asset.id);
          await reloadTrailers();
          setModal({ open: false });
        },
      },
      secondaryButton: {
        text: 'Cancel',
        className: 'outline',
        onClick: () => setModal({ open: false }),
      },
    });
  };

  return (
    <div className="trailers-side-pannel" data-testid="trailersPage-sidePanel">
      <div
        className="center-text flex padding-16px bold"
        data-testid="trailersPage-sideHeader"
      >
        {selectedTrailer
          ? `Pick a title for "${selectedTrailer.title}"`
          : 'Pick a trailer on the left'}
      </div>
      <div
        className="flex padding-16px align-end"
        data-testid="trailersPage-sideSearch"
      >
        <SearchSelect searchBy={searchBy} setSearchBy={setSearchBy} />
        <SearchBar onSearch={onSearch} placeholder="Search Available Titles" />
      </div>
      <div style={{ position: 'relative' }} data-testid="trailersPage-sideView">
        {loading ? (
          <KiteLoader
            buttonChild={false}
            className="asset-loader"
            loaderTitle="Loading"
            size="large"
            status=""
            useLight={false}
          />
        ) : null}
        {assets && !loading ? (
          assets.map((asset, i) => (
            <div
              className="asset-card"
              key={i}
              onClick={() => {
                openAssetModal(asset);
              }}
            >
              <img
                alt={`${asset.title} poster`}
                src={asset.imageUrl}
                className="asset-image"
              />
              <div className="asset-title-meta-data">
                <div className="asset-title">
                  {asset.title}
                  <KiteIcon
                    name="eye-f"
                    size="15px"
                    margin="0px 10px"
                    color="#0073d1"
                  />
                </div>
                <div className="asset-meta-data">
                  <KiteIcon margin="0" name="ticket-f" color="#9ba9bd" />
                  <div className="asset-meta-data-value">
                    {asset.objectType}
                  </div>
                  •<div className="asset-meta-data-value">{asset.language}</div>
                  •
                  <div className="asset-meta-data-value">
                    {asset.releaseYear}
                  </div>
                  •
                  <div className="asset-meta-data-value">
                    {
                      asset.trailers.filter(
                        (t) => !t.archived_at && t.status === 'MATCHED'
                      ).length
                    }{' '}
                    Trailers
                  </div>
                </div>
                {selectedTrailer ? (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      addToTitle(selectedTrailer, asset);
                    }}
                    className="add-to-title"
                  >
                    <KiteIcon color="#0073d1" name="plus-alt" margin="10px" />
                    Add To Title
                  </div>
                ) : null}
              </div>
            </div>
          ))
        ) : (
          <div className="title-search-tip" data-testid="trailersPage-sideTip">
            <KiteIcon name="search" size="70px" color="#d8dde6" />
            <div>Search by title name to view available titles</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetsSidePanel;
